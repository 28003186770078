@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/_mixins.scss';

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
    Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fieldLabel {
  font-weight: bolder;
  font-size: .7rem;
}
.fieldValue {
  font-style: normal;
  font-size: medium;
  display: block;
  font-weight: normal;
}